import { ReactNode } from 'react';
import Attention from '../_icons/Attention';
import ChevronCircleTick from '../_icons/Chevron-circle-tick';

type IconType = 'attention' | 'check';

function getIcon(iconType: IconType) {
	if (iconType === 'attention') {
		return <Attention className="mr-6" color="white" />;
	}

	if (iconType === 'check') {
		return (
			<span className="mr-4">
				<ChevronCircleTick className="" />
			</span>
		);
	}
	return;
}

export const AttentionBox: React.FC<{
	text: string;
	children?: ReactNode;
	className?: string;
	showIcon?: boolean;
	iconType?: IconType;
}> = props => {
	const { text, children, className, showIcon, iconType = 'attention' } = props;

	return (
		<div className="bg-gold-transparent rounded-lg">
			<div className={`bg-attention flex w-full items-center rounded-lg p-6 ${className}`}>
				{showIcon && getIcon(iconType)}
				<p className="font-bold text-white">{text}</p>
			</div>
			{children ? <div className="bg-gold-transparent rounded-lg p-6">{children}</div> : null}
		</div>
	);
};

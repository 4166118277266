import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
	color?: string;
	size?: 'default' | 'large';
}
const Spinner = ({
	title,
	titleId,
	desc,
	descId,
	color,
	className = 'size-4',
}: SVGProps<SVGSVGElement> & SVGRProps) => {
	return (
		<svg
			viewBox={`0 0 16 16`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className || ''}
		>
			{desc ? <desc id={descId}>{desc}</desc> : null}
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				d="M14 8C14 9.18669 13.6481 10.3467 12.9888 11.3334C12.3295 12.3201 11.3925 13.0892 10.2961 13.5433C9.19974 13.9974 7.99334 14.1162 6.82946 13.8847C5.66557 13.6532 4.59647 13.0818 3.75736 12.2426C2.91824 11.4035 2.3468 10.3344 2.11529 9.17054C1.88378 8.00666 2.0026 6.80026 2.45672 5.7039C2.91085 4.60754 3.67988 3.67047 4.66658 3.01118C5.65327 2.35189 6.81331 2 8 2"
				stroke={color ? color : '#293455'}
			/>
		</svg>
	);
};
export default Spinner;

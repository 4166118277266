'use client';
import React from 'react';
import { AttentionBox } from '../../_components/attention-box';
import { useTranslations } from 'next-intl';

export default function NoCoursesNotification() {
	const t = useTranslations('Course');

	return (
		<AttentionBox showIcon text={t('no-courses-title')}>
			<p>{t('no-courses-description')}</p>
		</AttentionBox>
	);
}

'use client';
import { usePathname, useRouter } from '@/navigation';
import { useParams } from 'next/navigation';
import { useOptimistic, useTransition } from 'react';
import { Pagination } from '../Pagination';
import { CoursesSearchParams } from '../../[locale]/courses/page';

export default function ListPagination({
	page,
	totalPages,
	searchParams,
}: {
	page: number;
	totalPages: number;
	searchParams?: CoursesSearchParams;
}) {
	const router = useRouter();
	const pathname = usePathname();
	const params = useParams();
	const [pending, startTransition] = useTransition();
	const [optimisticPage, setOptimisticPage] = useOptimistic(page);

	return (
		<Pagination
			page={page}
			totalPages={totalPages}
			pending={pending}
			onClick={newPage => {
				startTransition(() => {
					setOptimisticPage(newPage);
					const searchParamsWithoutPage = searchParams;

					delete searchParams?.page;

					router.push(
						// @ts-expect-error -- TypeScript will validate that only known `params`
						// are used in combination with a given `pathname`. Since the two will
						// always match for the current route, we can skip runtime checks.
						{
							pathname,
							params,
							query: {
								...searchParamsWithoutPage,
								...(newPage > 1 ? { page: newPage } : {}),
							},
						},
						{
							scroll: false,
						},
					);
				});
			}}
		/>
	);
}
